import CommonCss from "lib/common/style.module.scss";

import Css from "./style.module.scss";

import { Button } from "shards-react";
import { DropDownSelect, Page, Preloader } from "lib/common";
import {
  FiCheck,
  FiCheckSquare,
  FiChevronLeft,
  FiChevronRight,
  FiPlus,
  FiPlusCircle
} from "react-icons/fi";
import { getSelectedBusinessId } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Constants from "const/Constants";
import Features from "const/Features";
import PageHeader from "lib/common/PageHeader";
import React, { useCallback, useEffect, useMemo } from "react";
import TaskFilters from "const/TaskFilters";
import TaskListItem from "nlib/common/TaskListItem";
import UiActions from "actions/UiActions";
import classNames from "classnames";
import useAllowedFeature from "hooks/useAllowedFeature";
import useEnvVars from "hooks/useEnvVars";
import useTasks from "hooks/useTasks";

const {
  ACTIVE_FOR_BUSINESS,
  ACTIVE_FOR_ACCOUNTANT,
  ALL_FOR_BUSINESS,
  ALL_FOR_ACCOUNTANT,
  ALL_ACTIVE_TASKS,
  ALL_TASKS
} = TaskFilters;

const TasksPage = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const [envVars, setEnvVars] = useEnvVars();

  const checkIsAllowed = useAllowedFeature();

  const {
    fetchingData,
    tasksFilter,
    allTasksData,
    allActiveTasksData,
    tasksForBusinessData,
    tasksForAccountantData,
    activeTasksForBusinessData,
    activeTasksForAccountantData,
    filteredTasksData
  } = useTasks();

  const selectedBusinessId = useSelector(getSelectedBusinessId);

  const { uiTexts, messages } = useSelector(getTextsData);

  const tasksFilterOptions = useMemo(() => {
    const closedTasksForBusinessCount = tasksForBusinessData.length - activeTasksForBusinessData.length;

    const closedTasksForAccountantCount = tasksForAccountantData.length - activeTasksForAccountantData.length;

    const closedTasksCount = allTasksData.length - allActiveTasksData.length;

    return [
      {
        label: <>
          <FiChevronLeft />
          <span>{`${uiTexts.tasksForClient} (${closedTasksForBusinessCount}/${tasksForBusinessData.length})`}</span>
        </>,
        value: ALL_FOR_BUSINESS
      },
      {
        label: <>
          <FiChevronLeft />
          <span>{`${uiTexts.activeTasksForClient} (${activeTasksForBusinessData.length})`}</span>
        </>,
        value: ACTIVE_FOR_BUSINESS
      },
      { divider: true },
      {
        label: <>
          <FiChevronRight />
          <span>{`${uiTexts.tasksForAccountant} (${closedTasksForAccountantCount}/${tasksForAccountantData.length})`}</span>
        </>,
        value: ALL_FOR_ACCOUNTANT
      },
      {
        label: <>
          <FiChevronRight />
          <span>{`${uiTexts.activeTasksForAccountant} (${activeTasksForAccountantData.length})`}</span>
        </>,
        value: ACTIVE_FOR_ACCOUNTANT
      },
      { divider: true },
      {
        label: <>
          <FiCheck />
          <span>{`${uiTexts.allTasks} (${closedTasksCount}/${allTasksData.length})`}</span>
        </>,
        value: ALL_TASKS
      },
      {
        label: <>
          <FiCheck />
          <span>{`${uiTexts.allActiveTasks} (${allActiveTasksData.length})`}</span>
        </>,
        value: ALL_ACTIVE_TASKS
      }
    ];
  }, [
    activeTasksForAccountantData.length,
    activeTasksForBusinessData.length,
    allActiveTasksData.length,
    allTasksData.length,
    tasksForAccountantData.length,
    tasksForBusinessData.length,
    uiTexts
  ]);

  const handleTasksFilterSelectorChange = useCallback((value) => {
    setEnvVars({ tasksFilter: value });
    dispatch(UiActions.setTasksFilter(value));
  }, [dispatch, setEnvVars]);

  const handleAddTaskButtonClick = useCallback(() => {
    setEnvVars({ editTask: Constants.NEW_ENTITY_ID });
  }, [setEnvVars]);

  const handleSelectTask = useCallback((taskId) => {
    setEnvVars({ editTask: taskId });
  }, [setEnvVars]);

  useEffect(() => {
    if (!checkIsAllowed(Features.TASKS)) {
      history.replace(`/${selectedBusinessId}`);
    }
  }, [selectedBusinessId, history, checkIsAllowed]);

  return (
    <Page className={Css.tasksPage}>
      <PageHeader>
        <div className={Css.pageHeaderActions}>
          {!!allTasksData.length && (
            <>
              <DropDownSelect
                caret
                right
                size="sm"
                theme="white"
                key={tasksFilter}
                className={Css.dropDown}
                toggleClassName={Css.dropDownToggle}
                menuClassName={Css.dropDownMenu}
                initialValue={tasksFilter}
                disabled={fetchingData}
                options={tasksFilterOptions}
                onChange={handleTasksFilterSelectorChange} />
              {envVars.editTask !== Constants.NEW_ENTITY_ID && (
                <Button size="sm" disabled={fetchingData} onClick={handleAddTaskButtonClick}>
                  <FiPlus />
                  <span>{uiTexts.addNewTask}</span>
                </Button>
              )}
            </>
          )}
        </div>
      </PageHeader>
      <div className={classNames(Css.container, CommonCss.tasksBlock)}>
        {allTasksData.length
          ? (filteredTasksData.map((task) => (
            <TaskListItem
              key={task.id}
              task={task}
              onSelect={handleSelectTask} />
          )))
          : (fetchingData ? <Preloader absolute /> : (
            <div className={Css.emptyState}>
              <div className={Css.icon}><FiCheckSquare /></div>
              <h4>{messages.tasksBlockPlaceholder}</h4>
              <Button size="sm" onClick={handleAddTaskButtonClick}>
                <FiPlusCircle />
                <span>{uiTexts.addNewTask}</span>
              </Button>
            </div>
          ))}
      </div>
    </Page>
  );
};

export default TasksPage;
