import { checkTasksFetching, getTasksData } from "selectors/tasks";
import { useCallback, useLayoutEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Constants from "const/Constants";
import TaskFilters from "const/TaskFilters";
import TasksActions from "actions/TasksActions";
import UserRoles from "const/UserRoles";
import Utils from "utils/Utils";
import useEnvVars from "./useEnvVars";

const {
  ACTIVE_FOR_BUSINESS,
  ACTIVE_FOR_ACCOUNTANT,
  ALL_FOR_BUSINESS,
  ALL_FOR_ACCOUNTANT,
  ALL_ACTIVE_TASKS,
  ALL_TASKS
} = TaskFilters;

const useTasks = () => {
  const dispatch = useDispatch();

  const [{ tasksFilter = ALL_TASKS }] = useEnvVars();

  const fetchingData = useSelector(checkTasksFetching);

  const allTasksData = useSelector(getTasksData);

  const allActiveTasksData = useMemo(() => {
    return allTasksData.filter(({ completed }) => !completed);
  }, [allTasksData]);

  const tasksForBusinessData = useMemo(() => {
    return allTasksData.filter(({ createdBy, targetUser }) => targetUser
      ? UserRoles.checkIsBusiness(targetUser.role)
      : (!createdBy || UserRoles.checkIsAccountant(createdBy.role)));
  }, [allTasksData]);

  const tasksForAccountantData = useMemo(() => {
    return allTasksData.filter(({ createdBy, targetUser }) => targetUser
      ? UserRoles.checkIsAccountant(targetUser.role)
      : (createdBy && UserRoles.checkIsBusiness(createdBy.role)));
  }, [allTasksData]);

  const activeTasksForBusinessData = useMemo(() => {
    return tasksForBusinessData.filter(({ completed }) => !completed);
  }, [tasksForBusinessData]);

  const activeTasksForAccountantData = useMemo(() => {
    return tasksForAccountantData.filter(({ completed }) => !completed);
  }, [tasksForAccountantData]);

  const filteredTasksData = {
    [ACTIVE_FOR_BUSINESS]: activeTasksForBusinessData,
    [ACTIVE_FOR_ACCOUNTANT]: activeTasksForAccountantData,
    [ALL_FOR_BUSINESS]: tasksForBusinessData,
    [ALL_FOR_ACCOUNTANT]: tasksForAccountantData,
    [ALL_ACTIVE_TASKS]: allActiveTasksData,
    [ALL_TASKS]: allTasksData
  }[tasksFilter];

  const fetchData = useCallback((clearList, backgroundUpdate) => {
    dispatch(TasksActions.fetchTasksList(clearList, backgroundUpdate));
  }, [dispatch]);

  useLayoutEffect(() => {
    fetchData(true, false);

    return Utils.setInterval(() => fetchData(false, true), Constants.DATA_LIST_UPDATE_INTERVAL);
  }, [fetchData]);

  return {
    tasksFilter,
    fetchingData,
    allTasksData,
    filteredTasksData,
    allActiveTasksData,
    tasksForBusinessData,
    tasksForAccountantData,
    activeTasksForBusinessData,
    activeTasksForAccountantData,
    fetchData
  };
};

export default useTasks;
